<template>
  <div>
    <defaultFieldTypes
      :fieldAttributes="{
        ...fieldAttributes,
        associatedOption: result.additional.devices,
      }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
    :value="value"></defaultFieldTypes>
  </div>
</template>

<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result","value"],
};
</script>